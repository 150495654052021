import { StationsService } from './services/stations.service';
import { Component, OnInit } from '@angular/core';

import { Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { StationResponse } from './models/station-response';
import { NavigationEnd, Router, Event } from '@angular/router';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';

declare var _paq;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public stations: StationResponse[];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private stationsService: StationsService,
    router: Router,
    clipboardService: ClipboardService,
    toastController: ToastController
  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        // Navigation Ended Successfully.
        _paq.push(['setCustomUrl', event.url]);
        _paq.push(['setDocumentTitle', 'Gas Price Watcher']);
        _paq.push(['trackPageView']);
      }
    });

    clipboardService.configure({ cleanUpAfterCopy: true });

    clipboardService.copyResponse$.subscribe(async (res: IClipboardResponse) => {
      if (res.isSuccess) {
        const toast = await toastController.create({
          message: 'copied.',
          duration: 1000,
          color: 'success',
          mode: 'ios'
        });
        toast.present()
      }
    });

    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    this.stationsService.onStationAdded.subscribe(() => this.updateStations());
    this.updateStations();
  }

  updateStations() {
    this.stationsService.getAll().subscribe(s => this.stations = s);
  }
}
