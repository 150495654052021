import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full'
  },
  {
    path: 'start',
    loadChildren: () => import('./pages/start/start.module').then(m => m.StartPageModule)
  },
  {
    path: 'station/:id',
    loadChildren: () => import('./pages/station/station.module').then(m => m.StationPageModule)
  },
  {
    path: 'imprint',
    loadChildren: () => import('./pages/imprint/imprint.module').then(m => m.ImprintPageModule)
  },
  {
    path: 'add-station',
    loadChildren: () => import('./pages/add-station/add-station.module').then( m => m.AddStationPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
