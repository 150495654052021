import { StationRequest } from './../models/station-request';
import { StationResponse } from './../models/station-response';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StationsService {
  public onStationAdded: EventEmitter<void> = new EventEmitter();

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<StationResponse[]>(`${environment.apiRoot}stations`);
  }

  getById(id: number) {
    return this.http.get<StationResponse>(`${environment.apiRoot}stations/${id}`);
  }

  getTypes() {
    return this.http.get<{ [key: number]: string }>(`${environment.apiRoot}stations/types`);
  }

  create(request: StationRequest) {
    const req = this.http.post<StationResponse>(`${environment.apiRoot}stations`, request);

    req.subscribe(d => {
      console.info(d);
      this.onStationAdded.next();
    });

    return req;
  }
}
